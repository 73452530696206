import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';

interface Translation {
  en: {
    [key: string]: string;
  };
  fi: {
    [key: string]: string;
  };
}
const translations: Translation = {
  en: {
    welcome: 'welcome',
  },
  fi: {
    welcome: 'tervetuloa',
  },
};

interface TranslationContextProps {
  lang: 'en' | 'fi';
  setLang: (lang: 'en' | 'fi') => void;
  translate: (key: string) => string;
}

const TranslationContext = createContext<TranslationContextProps>({
  lang: 'en',
  setLang: () => {},
  translate: (key: string) => key,
});

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const [lang, setLang] = useState<Locale>(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang === 'en' || savedLang === 'fi') {
      return savedLang;
    }
    return 'fi';
  });

  useEffect(() => {
    localStorage.setItem('lang', lang);
  }, [lang]);

  const translate = (key: string) => {
    return translations[lang][key] || key;
  };

  return (
    <TranslationContext.Provider value={{ lang, setLang, translate }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  return useContext(TranslationContext);
};
