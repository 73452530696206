import {
  FaFacebook,
  FaXTwitter,
  FaInstagram,
  FaEnvelope,
  FaHeart,
} from 'react-icons/fa6';

import { Link } from 'react-router-dom';
import { useTranslation } from '../store/translation';
import { capitalize } from '@maw/utility';
import { translations } from '../data/translation';

export default function Footer() {
  const { translate, lang } = useTranslation();

  const footerLinks = [
    {
      name: `${capitalize(translate(translations[lang].nav.contacts))}`,
      link: '/contacts',
    },
    {
      name: `${capitalize(translate(translations[lang].nav.blogPosts))}`,
      link: '/blog-posts',
    },
    {
      name: `${capitalize(translate(translations[lang].privacyPolicy.title))}`,
      link: '/privacy-policy',
    },
  ];

  return (
    <footer className='relative bg-green-800 opacity-80 px-4 '>
      <div className='absolute -top-20 left-0 w-full overflow-hidden pointer-events-none rotate-180 z-10 '>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
          className='h-20 w-full'
        >
          <path
            d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
            opacity='.25'
            fill='#156534'
          ></path>
          <path
            d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
            opacity='.5'
            fill='#156534'
          ></path>
          <path
            d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
            fill='#156534'
          ></path>
        </svg>
      </div>
      <div className='flex flex-col border-b md:max-w-7xl m-auto'>
        <div className='flex justify-center text-white py-16 flex-col md:flex-row'>
          <div className='my-4 tracking-wider'>
            <h1 className='mb-6 uppercase'>
              {capitalize(translate(translations[lang].form.address))}
            </h1>
            <p className='text-green-200 text-sm'>Tykistökatu 4,</p>
            <p className='text-green-200 text-sm'>20520</p>
            <p className='text-green-200 text-sm'>
              Turku, {capitalize(translate(translations[lang].country.finland))}
            </p>
            <div className='flex items-center mt-4'>
              <FaEnvelope className='h-4 w-4 fill-green-200' />
              <span className='text-green-200 ml-2 hover:text-green-300'>
                <p className='text-sm'>
                  <a
                    href='mailto:asiakaspalvelu@maw.fi'
                    target='_blank'
                    rel='noreferrer'
                  >
                    asiakaspalvelu@maw.fi
                  </a>
                </p>
              </span>
            </div>
          </div>
          <div className='my-4 tracking-wider md:ml-10'>
            <h1 className='mb-6 uppercase'>
              {capitalize(translate(translations[lang].nav.links))}
            </h1>
            <ul>
              {footerLinks.map((item) => (
                <li
                  className='text-green-200 mb-3 hover:text-green-300'
                  key={item.name}
                >
                  <Link to={item.link} key={item.name}>
                    <p className='text-sm'>{item.name}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <ul className='flex justify-center mb-6'>
          <li className='cursor-pointer'>
            <FaFacebook className='fill-white h-7 w-7' />
          </li>
          <li className='mx-6 cursor-pointer'>
            <FaXTwitter className='fill-white h-7 w-7' />
          </li>
          <li className='cursor-pointer'>
            <FaInstagram className='h-7 w-7 fill-white' />
          </li>
        </ul>
        {lang === 'en' ? (
          <div className='flex items-center gap-x-2 justify-center my-2 font-light text-sm text-orange-200'>
            Built with{' '}
            <span>
              <FaHeart className='fill-red-500 h-4 w-4' />
            </span>{' '}
            by{' '}
            <a
              href='https://dayooladapo.com'
              className='hover:text-white transition-colors'
              target='_blank'
              rel='noreferrer'
            >
              Oladayo Oladapo
            </a>
          </div>
        ) : (
          <div className='flex items-center gap-x-2 justify-center my-2 font-light text-sm text-orange-200'>
            Kehitetty{' '}
            <span>
              <FaHeart className='fill-red-500 h-4 w-4' />
            </span>{' '}
            <a
              href='https://dayooladapo.com'
              className='hover:text-white transition-colors'
              target='_blank'
              rel='noreferrer'
            >
              Oladayo Oladapo
            </a>
          </div>
        )}
      </div>
      <div className='flex flex-col justify-center items-center text-center py-10 text-green-200 gap-3 m-auto'>
        <small>&copy; {new Date().getFullYear()} maw.fi</small>
      </div>
    </footer>
  );
}
