import { useQuery } from '@tanstack/react-query';
import Container from '../Container';
import {
  LoaderFunction,
  LoaderFunctionArgs,
  useParams,
} from 'react-router-dom';
import {
  getById,
  getLocaleFromStorage,
  queryClient,
} from '../../util/requests';
import TinyMCEContent from '../TinyMCEContent';
import { ENV } from '../../util/constants';
import { useTranslation } from '../../store/translation';
import { useEffect } from 'react';
import BounceModal from '../modals/BounceModal';
import { CustomDate } from '@maw/utility';
import { useMeta } from '../../hooks/metadata';

export default function BlogPostDetailts() {
  const { lang } = useTranslation();
  const params = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['blog-posts', params.slug, lang],
    queryFn: ({ signal }) =>
      getById({
        signal,
        url: `blog-posts/${params.slug}`,
        locale: lang,
      }),
  });

  const blogPost: BlogPost = data?.data;

  useEffect(() => {
    refetch();
  }, [refetch, lang]);

  useMeta({
    title: `${blogPost?.translation?.title}`,
    description: `${blogPost?.translation?.content.substring(0, 100)}...`
  })

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          <section className='py-24 sm:py-32'>
            <div className='mx-auto max-w-5xl px-6 lg:px-8'>
              <div className='mx-auto sm:text-center'>
                <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                  {blogPost.translation.title}
                </h2>
                <div className='flex flex-col'>
                  <p className='inline-block text-gray-700'>{`${blogPost.author.firstName} ${blogPost.author.lastName}`}</p>
                  <p className='inline-block text-gray-500 my-2'>
                    {CustomDate.getDayMonthYearHyphenated(
                      blogPost.translation.publishedAt
                    )}
                  </p>
                </div>
                {blogPost.coverImage && (
                  <div className='my-8 rounded-lg overflow-hidden'>
                    <img
                      className='w-full object-cover'
                      src={`${ENV.assetsUrl}/${blogPost.coverImage}`}
                      alt={blogPost.translation.title}
                    />
                  </div>
                )}
              </div>
              {<TinyMCEContent content={blogPost.translation.content} />}
            </div>
          </section>
        </Container>
      )}
    </>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  return await queryClient.fetchQuery({
    queryKey: ['blog-posts', params.slug],
    queryFn: ({ signal }) =>
      getById({
        signal,
        url: `blog-posts/${params.slug}`,
        locale,
      }),
  });
}
