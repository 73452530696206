import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { ENV } from '../util/constants';

type TestimmonialFullProps = {
  leftAlignImage: boolean;
  className?: string;
} & Testimonial;

export default function TestimonialSection({
  leftAlignImage,
  description,
  photo,
  companyName,
  firstName,
  lastName,
  className,
}: TestimmonialFullProps) {
  return (
    <li
      className={`flex flex-col border-b-2 gap-4 border-green-500 mb-4 py-6 md:flex-row md:space-x-2 justify-between align-middle ${className}`}
    >
      {leftAlignImage ? (
        <>
          <div className='w-full rounded-lg overflow-hidden flex-1'>
            <img
              src={`${ENV.assetsUrl}/${photo}`}
              alt='Customer'
              className='object-cover rounded-lg w-full flex-1'
            />
          </div>
          <div className='relative flex-1'>
            <p>{description}</p>
            <p className='text-gray-400 mt-4'>{`${firstName} ${lastName}, ${companyName}`}</p>
            {/* <p className='text-gray-400 mt-4'>{`${firstName} ${lastName}, ${title}-${companyName}`}</p> */}
            <ChatBubbleLeftEllipsisIcon
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full text-gray-100 -z-10'
              aria-hidden='true'
            />
          </div>
        </>
      ) : (
        <>
          <div className='relative flex-1'>
            <p>{description}</p>
            <p className='text-gray-400 mt-4'>{`${firstName} ${lastName}, ${companyName}`}</p>
            {/* <p className='text-gray-400 mt-4'>{`${firstName} ${lastName}, ${title}-${companyName}`}</p> */}
            <ChatBubbleLeftEllipsisIcon
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full text-gray-100 -z-10'
              aria-hidden='true'
            />
          </div>
          <div className='rounded-lg overflow-hidden flex-1'>
            <img
              src={`${ENV.assetsUrl}/${photo}`}
              alt='Customer'
              className='object-cover rounded-lg flex-1'
            />
          </div>
        </>
      )}
    </li>
  );
}
