import { Link } from 'react-router-dom';
import { CButton, capitalize } from '@maw/utility';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';

type CookieProps = {
  className?: string;
  onAccept: () => void;
  onReject: () => void;
};

export default function Cookie({ className, onAccept, onReject }: CookieProps) {
  const { translate, lang } = useTranslation();

  return (
    <div
      className={`${
        className && className
      } p-4 bg-white border-t-2 ring-green-600 mx-auto py-8`}
    >
      <div className='max-w-7xl m-auto'>
        <p className='mb-4 text-sm text-slate-600 max-w-7xl'>
          {translate(translations[lang].privacyPolicy.description)}{' '}
          <Link
            to='/privacy-policy'
            className='text-green-400 hover:text-green-600'
          >
            {translate(translations[lang].privacyPolicy.title)}
          </Link>
        </p>
        <div className='flex gap-x-4 w-max'>
          <CButton pill variant='success' type='button' onClick={onAccept}>
            {capitalize(translate(translations[lang].btn.accept))}
          </CButton>
          <CButton pill outline variant='info' type='button' onClick={onReject}>
            {capitalize(translate(translations[lang].btn.decline))}
          </CButton>
        </div>
      </div>
    </div>
  );
}
