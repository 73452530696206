import React from 'react';

type ContainerProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

export default function Container({ children, ...rest }: ContainerProps) {
  return (
    <main className='p-6 my-32' {...rest}>
      {children}
    </main>
  );
}
