import {
  CogIcon,
  BuildingOfficeIcon,
  HomeIcon,
  BuildingStorefrontIcon,
  FolderIcon,
} from '@heroicons/react/24/outline';
import { CLink } from '@maw/utility';
import { useQuery } from '@tanstack/react-query';

import ServiceCard from '../cards/ServiceCard';
import Container from '../Container';
import BlogCard from '../cards/BlogCard';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';
import {
  getLocaleFromStorage,
  getResources,
  queryClient,
} from '../../util/requests';
import { useEffect } from 'react';
import BounceModal from '../modals/BounceModal';
import { LoaderFunction } from 'react-router-dom';
import CustomerLogo from '../CustomerLogo';
import { useMeta } from '../../hooks/metadata';

interface Resources {
  blogPosts: BlogPost[];
  services: Service[];
  trustBadges: TrustBadge[];
  customers: Customer[];
}

const icons = [
  {
    name: ['restaurant', 'ravintola'],
    value: <BuildingStorefrontIcon className='h-10 w-10 fill-green-400' />,
  },
  {
    name: ['office', 'toimisto'],
    value: <BuildingOfficeIcon className='h-10 w-10 fill-green-400' />,
  },
  {
    name: ['supermarket', 'myymälä'],
    value: <CogIcon className='h-10 w-10 fill-green-400' />,
  },
  {
    name: ['home', 'koti'],
    value: <HomeIcon className='h-10 w-10 fill-green-400' />,
  },
  {
    name: ['other', 'muu'],
    value: <FolderIcon className='h-10 w-10 fill-green-400' />,
  },
];

export default function Home() {
  const { translate, lang } = useTranslation();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['home-page-resources', lang],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'home-page/resources', locale: lang }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [lang, refetch]);

  function addIconToService(data: Resources) {
    const result: ServiceWithIcon[] = [];
    if (data && data.services) {
      data.services.forEach((service) => {
        icons.forEach((element) => {
          const serviceCategoryIndex = element.name.indexOf(
            service.translation.serviceCategory
          );
          if (serviceCategoryIndex !== -1) {
            result.push({
              ...service,
              icon: { name: [...element.name], value: element.value },
            });
          }
        });
      });
    }
    return { ...data, services: result };
  }

  const resources = addIconToService(data?.data);

  useMeta({
    title: `${translate(translations[lang].metadata.home.title)}`,
    description: `${translate(translations[lang].metadata.home.description)}`,
    keywords: translate(translations[lang].metadata.home.keywords)
  });

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          <section className='h-[80vh] -m-6'>
            <div
              className='h-full relative bg-gray bg-cover bg-center bg-no-repeat bg-blend-overlay bg-fixed sm:bg-[url("/src/assets/ai-image.jpg")]'
            >
              <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full md:w-1/2 p-4 bg-gray-600 bg-opacity-30 rounded-md'>
                <h2 className='text-3xl md:text-7xl font-bold tracking-wide text-white sm:text-4xl'>
                  {translate(translations[lang].cta)}
                  <br />
                </h2>
                <CLink
                  rounded
                  type='button'
                  href='/enquiries'
                  variant='success'
                  className='mt-8'
                >
                  {translate(translations[lang].btn.scheduleNow)}
                </CLink>
              </div>
            </div>
          </section>
          {/* Section services */}
          {resources && resources.services.length > 0 && (
            <section className='bg-slate-100 py-24 sm:py-32 -mx-6 -mb-6'>
              <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-2xl sm:text-center'>
                  <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                    {translate(translations[lang].services.title)}
                  </h2>
                  <p className='mt-6 text-lg leading-8 text-gray-600'>
                    {translate(translations[lang].services.subTitle)}
                  </p>
                </div>
                <div className='p-4 sm:flex sm:flex-wrap gap-4 justify-center mt-8'>
                  {resources.services.map((service) => (
                    <ServiceCard
                      id={service.id}
                      key={service.id}
                      icon={service.icon}
                      translation={service.translation}
                    />
                  ))}
                </div>
              </div>
            </section>
          )}
          {/* Customers's logo display */}
          {resources && resources.customers.length > 0 && (
            <section className='bg-indigo-100 py-24 sm:py-32 -mx-6 -mb-6'>
              <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-2xl sm:text-center'>
                  <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                    {translate(translations[lang].customers.title)}
                  </h2>
                  <p className='mt-6 text-lg leading-8 text-gray-600'>
                    {translate(translations[lang].customers.subTitle)}
                  </p>
                </div>
                <div className='p-4 sm:flex sm:flex-wrap gap-4 justify-center mt-8'>
                  {resources.customers.map((customer) => (
                    <CustomerLogo
                      key={customer.id}
                      logo={customer.logo}
                      companyName={customer.companyName}
                    />
                  ))}
                </div>
              </div>
            </section>
          )}
          {/* BlogPost */}
          {resources && resources.blogPosts.length > 0 && (
            <section className='bg-slate-100 py-24 sm:py-32 -mx-6 -mb-6'>
              <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-2xl sm:text-center'>
                  <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                    {translate(translations[lang].blog.title)}
                  </h2>
                  <p className='mt-6 text-lg leading-8 text-gray-600'>
                    {translate(translations[lang].blog.subTitle)}
                  </p>
                </div>
                <div className='flex gap-4 flex-wrap justify-center mt-8'>
                  {resources.blogPosts.map((post) => (
                    <BlogCard
                      key={post.id}
                      id={post.id}
                      translation={post.translation}
                      coverImage={post.coverImage}
                      author={post.author}
                    />
                  ))}
                </div>
                <div className='text-center mt-8'>
                  <CLink rounded href='/blog-posts' variant='success'>
                    {translate(translations[lang].btn.readMore)}
                  </CLink>
                </div>
              </div>
            </section>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  return await queryClient.fetchQuery({
    queryKey: ['home-page-resources'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'home-page/resources', locale }),
  });
}
