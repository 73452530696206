import {
  Link,
  LoaderFunction,
  LoaderFunctionArgs,
  useParams,
} from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Container from '../Container';
import { capitalize } from '@maw/utility';
import { getById, queryClient } from '../../util/requests';
import { useQuery } from '@tanstack/react-query';
import TinyMCEContent from '../TinyMCEContent';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';
import { useEffect } from 'react';
import BounceModal from '../modals/BounceModal';
import { useMeta } from '../../hooks/metadata';

export default function RecruitmentDetails() {
  const params = useParams();
  const { translate, lang } = useTranslation();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['recruitments', params.id],
    queryFn: ({ signal }) =>
      getById({
        signal,
        url: `recruitments/${params.id}`,
      }),
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const recruitment = data?.data;

  useMeta({
    title: `${recruitment.title}`,
  });

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          {data && (
            <section className='mt-12 mx-auto max-w-5xl lg:px-8 md:mt-20 bg-green-100/45 rounded-lg p-10'>
              <div className='my-10'>
                <Link
                  to='/recruitments'
                  className='flex justify-center items-center gap-x-1 hover:font-bold hover:transition-all'
                >
                  <ChevronLeftIcon
                    className='h-8 w-8 text-green-600'
                    aria-hidden='true'
                  />
                  <h1 className='uppercase tracking-wide text-green-600 md:text-xl max-w-fit'>
                    {capitalize(
                      translate(translations[lang].nav.openPositions)
                    )}
                  </h1>
                </Link>
                <div className='text-center'>
                  <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl my-10'>
                    {recruitment.title}
                  </h2>
                  {/* <CLink
                    variant='primary'
                    rounded
                    href='#'
                    disabled={navigation.state === 'loading'}
                  >
                    {`${
                      navigation.state === 'loading'
                        ? translate(translations[lang].btn.loading) + '...'
                        : translate(translations[lang].btn.applyNow)
                    }`}
                  </CLink> */}
                </div>
              </div>
              {<TinyMCEContent content={recruitment.description} />}
            </section>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['recruitments', params.id],
    queryFn: ({ signal }) =>
      getById({ signal, url: `recruitments/${params.id}` }),
  });
}
