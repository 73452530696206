import {
  Fa1,
  Fa2,
  Fa3,
  Fa4,
  Fa5,
  Fa6,
  Fa7,
  Fa8,
  Fa9,
  FaCircleDot,
} from 'react-icons/fa6';
import Container from '../Container';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';

export default function PrivacyPolicy() {
  const { translate, lang } = useTranslation();
  return (
    <Container>
      <div className='md:max-w-5xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm p-8 border-t-4 border-green-600'>
        <h1 className='text-base font-semibold leading-7 text-green-600 uppercase'>
          {translate(translations[lang].privacyPolicy.title)}
        </h1>
        <div className='my-6 leading-8 text-gray-700'>
          <p className='text-lg mb-4'>
            <strong>{translate(translations[lang].companyName)}.</strong>
          </p>
          <p className='mb-4'>
            {translate(translations[lang].privacyPolicy.introduction)}
          </p>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa1
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <div className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.information.title
                )}
                :
              </strong>{' '}
              <p>
                <span className='font-bold'>a.</span>{' '}
                <span className='text-gray-500'>
                  {translate(
                    translations[lang].privacyPolicy.category.information.type
                      .personal.title
                  )}
                  :
                </span>{' '}
                {translate(
                  translations[lang].privacyPolicy.category.information.type
                    .personal.description
                )}
              </p>
              <p>
                <span className='font-bold'>b.</span>
                <span className='text-slate-500'>
                  {translate(
                    translations[lang].privacyPolicy.category.information.type
                      .nonPersonal.title
                  )}
                  :
                </span>{' '}
                {translate(
                  translations[lang].privacyPolicy.category.information.type
                    .nonPersonal.description
                )}
              </p>
            </div>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa2
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <div className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(translations[lang].privacyPolicy.category.how.title)}
                :
              </strong>{' '}
              <p>
                <span className='font-bold'>a.</span>{' '}
                <span className='text-gray-500'>
                  {translate(
                    translations[lang].privacyPolicy.category.how.type
                      .communication.title
                  )}
                  :
                </span>{' '}
                {translate(
                  translations[lang].privacyPolicy.category.how.type
                    .communication.description
                )}
              </p>
              <p>
                <span className='font-bold'>b.</span>{' '}
                <span className='text-gray-500'>
                  {translate(
                    translations[lang].privacyPolicy.category.how.type.analytics
                      .title
                  )}
                  :
                </span>{' '}
                {translate(
                  translations[lang].privacyPolicy.category.how.type.analytics
                    .description
                )}
              </p>
            </div>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa3
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.protection.title
                )}
                :
              </strong>{' '}
              {translate(
                translations[lang].privacyPolicy.category.protection.description
              )}
            </p>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa4
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.thirdParty.title
                )}
                :
              </strong>{' '}
              {translate(
                translations[lang].privacyPolicy.category.thirdParty.description
              )}
            </p>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa5
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.cookie.title
                )}
                :
              </strong>{' '}
              {translate(
                translations[lang].privacyPolicy.category.cookie.description
              )}
            </p>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa6
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.yourChoice.title
                )}
                :
              </strong>{' '}
              {translate(
                translations[lang].privacyPolicy.category.yourChoice.description
              )}
            </p>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa7
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.children.title
                )}
                :{' '}
              </strong>
              {translate(
                translations[lang].privacyPolicy.category.children.description
              )}
            </p>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa8
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.thisPrivacy.title
                )}
                :{' '}
              </strong>
              {translate(
                translations[lang].privacyPolicy.category.thisPrivacy
                  .description
              )}
            </p>
          </div>
          <div className='flex gap-x-3'>
            <div className='flex relative'>
              <Fa9
                className='mt-1 h-5 w-5 flex-none text-green-600'
                aria-hidden='true'
              />
              <FaCircleDot
                className='mt-1 h-1 w-1 flex-none text-green-600 absolute top-4 left-5'
                aria-hidden='true'
              />
            </div>
            <p className='text-md mb-4'>
              <strong className='text-slate-500'>
                {translate(
                  translations[lang].privacyPolicy.category.contactUs.title
                )}
                :
              </strong>{' '}
              {translate(
                translations[lang].privacyPolicy.category.contactUs.description
                  .a
              )}{' '}
              <strong className='text-slate-500'>
                asiakaspalvelu@maw.fi/Tykistökatu 4, 20520, Turku.
              </strong>{' '}
              {translate(
                translations[lang].privacyPolicy.category.contactUs.description
                  .b
              )}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
