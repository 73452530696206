import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Home, { loader as homePageLoader } from './components/pages/Home';
import Layout from './components/Layout';
import Contacts from './components/pages/Contacts';
import Recruitments, {
  loader as jobsLoader,
} from './components/pages/Recruitments';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import ErrorBoundary from './components/ErrorBoundary';
import AboutUs, { loader as aboutUsLoader } from './components/pages/AboutUs';
import Testimonials from './components/pages/Testimonials';
import RecruitmentDetails, {
  loader as jobLoader,
} from './components/pages/RecruitmentDetails';
import Enquiry, {
  action as newEnquiryAction,
} from './components/pages/Enquiry';
import BlogPosts from './components/pages/BlogPosts';
import BlogPostDetailts, {
  loader as blogPostLoader,
} from './components/pages/BlogPostDetails';
import ServiceDetails, {
  loader as serviceLoader,
} from './components/pages/ServiceDetails';

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: '/',
          element: <Home />,
          loader: homePageLoader,
        },
        {
          path: 'contacts',
          element: <Contacts />,
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicy />,
        },
        {
          path: 'about-us',
          element: <AboutUs />,
          loader: aboutUsLoader,
        },
        {
          path: 'testimonials',
          element: <Testimonials />,
        },
        {
          path: 'enquiries',
          element: <Enquiry />,
          action: newEnquiryAction,
        },
        {
          path: 'recruitments',
          element: <Recruitments />,
          loader: jobsLoader,
        },
        {
          path: 'recruitments/:id',
          element: <RecruitmentDetails />,
          loader: jobLoader,
        },
        {
          path: 'blog-posts',
          element: <BlogPosts />,
          loader: jobsLoader,
        },
        {
          path: 'blog-posts/:slug',
          element: <BlogPostDetailts />,
          loader: blogPostLoader,
        },
        {
          path: 'services/:id',
          element: <ServiceDetails />,
          loader: serviceLoader,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
