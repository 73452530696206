import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { STATUS_CODE } from '@maw/utility';

type DataObj = {
  statusCode: number;
};

export function useRedirect(
  dataObj: DataObj,
  link?: string,
  shouldRedirect = false
) {
  const [data, setData] = useState<DataObj | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      data &&
      [STATUS_CODE.ok, STATUS_CODE.created, STATUS_CODE.noContent].includes(
        data.statusCode
      )
    ) {
      if (shouldRedirect && link) {
        setTimeout(() => {
          navigate(`/${link}`);
        }, 3000);
      } else {
        navigate('/');
      }
    }
  }, [data, link, navigate, shouldRedirect]);

  useEffect(() => {
    setData(dataObj);
  }, [dataObj]);
}
