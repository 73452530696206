import { capitalize } from '@maw/utility';
import { useQuery } from '@tanstack/react-query';
import Container from '../Container';
import ContactCard from '../cards/ContactCard';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';
import {
  getLocaleFromStorage,
  getResources,
  queryClient,
} from '../../util/requests';
import { useEffect } from 'react';
import BounceModal from '../modals/BounceModal';
import { LoaderFunction } from 'react-router-dom';
import { useMeta } from '../../hooks/metadata';

export default function Contacts() {
  const { translate, lang } = useTranslation();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['contacts', lang],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'contacts', locale: lang }),
  });

  const contacts: Contact[] = data?.data;

  useEffect(() => {
    refetch();
  }, [lang, refetch]);

  useMeta({
    title: `${capitalize(translate(translations[lang].nav.contacts))}`,
  });

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          <section className='max-w-7xl m-auto'>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center my-12'>
              {capitalize(translate(translations[lang].nav.contacts))}
            </h2>
            <div className='flex gap-4 flex-wrap justify-center'>
              {contacts &&
                contacts.length > 0 &&
                contacts.map((contact) => (
                  <ContactCard
                    id={contact.id}
                    key={contact.id}
                    firstName={contact.firstName}
                    lastName={contact.lastName}
                    translation={contact.translation}
                    telephone={contact.telephone}
                    email={contact.email}
                    avatar={contact.avatar}
                  />
                ))}
            </div>
          </section>
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  return await queryClient.fetchQuery({
    queryKey: ['contacts', locale],
    queryFn: ({ signal }) =>
      getResources({
        signal,
        url: 'contacts',
        locale,
      }),
  });
}
