type CustomerLogoProps = Pick<Customer, 'companyName' | 'logo'>;

export default function CustomerLogo({ companyName, logo }: CustomerLogoProps) {
  return (
    <div className='w-20'>
      <img
        src={logo}
        alt={`${companyName} logo`}
        className='object-cover w-full'
      />
    </div>
  );
}
