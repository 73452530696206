import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { getResources, queryClient } from '../util/requests';
import { useTranslation } from './translation';

export interface ServiceNavigation {
  id: string;
  translations: {
    title: string;
    serviceCategory: string;
  };
}

interface ServiceTranslationContextProps {
  services: ServiceNavigation[];
}

const ServiceTranslationContext = createContext<ServiceTranslationContextProps>(
  {
    services: [],
  }
);

export const ServiceTranslationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { lang } = useTranslation();
  const [services, setServices] = useState<ServiceNavigation[]>([]);

  useEffect(() => {
    const fetchServices = async () => {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['services-navigation', lang],
        queryFn: ({ signal }) =>
          getResources({
            signal,
            url: 'services/navigation/locale',
            locale: lang,
          }),
      });
      setServices(data);
    };

    fetchServices();
  }, [lang]);

  return (
    <ServiceTranslationContext.Provider value={{ services }}>
      {children}
    </ServiceTranslationContext.Provider>
  );
};

export const useServiceTranslation = () => {
  return useContext(ServiceTranslationContext);
};
