import {
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';
import { ENV } from '../../util/constants';

export default function ContactCard({
  id,
  translation,
  avatar,
  telephone,
  firstName,
  lastName,
  email,
}: Contact) {
  return (
    <figure className='rounded-lg hover:shadow-lg w-full md:w-96'>
      {avatar && (
        <div className='rounded-t-lg h-64 overflow-clip'>
          <img
            src={`${ENV.assetsUrl}/${avatar}`}
            alt={`${firstName} ${lastName}`}
            className='object-cover w-full h-full rounded-t-lg'
          />
        </div>
      )}
      <figcaption className='bg-green-500 text-white p-4 rounded-b-lg'>
        <div className='flex items-center gap-x-2 mb-2'>
          <UserIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{`${firstName} ${lastName}`}</p>
        </div>
        <div className='flex items-center gap-x-2 mb-2'>
          <Square3Stack3DIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{translation.department}</p>
        </div>
        <div className='flex items-center gap-x-2 mb-2'>
          <PhoneIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{telephone}</p>
        </div>
        <div className='flex items-center gap-x-2 mb-2'>
          <EnvelopeIcon className='h-7 w-7' aria-hidden='true' />
          <p className='italic'>{email}</p>
        </div>
      </figcaption>
    </figure>
  );
}
