import { Fragment, useState } from 'react';
import {
  Dialog,
  Disclosure,
  MenuButton,
  MenuItem,
  MenuItems,
  DisclosureButton,
  PopoverButton,
  PopoverPanel,
  PopoverGroup,
  DialogPanel,
  DisclosurePanel,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { MdOutlineTranslate, MdRestaurant } from 'react-icons/md';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useTranslation } from '../store/translation';
import { FaHouse, FaCreativeCommonsRemix, FaStoreSlash, FaBuildingColumns } from 'react-icons/fa6';
import { translations } from '../data/translation';
import { capitalize } from '@maw/utility';
import {
  ServiceNavigation,
  useServiceTranslation,
} from '../store/service-translation';
import { IconType } from 'react-icons';
import logoWithText from '../assets/logo-with-text.png';
import logoNoText from '../assets/logo-no-text.png';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function DropdownMenu() {
  const { setLang, lang } = useTranslation();
  return (
    <>
      <Menu as='div' className='relative ml-auto'>
        <div>
          <MenuButton className='relative flex rounded-full text-sm focus:outline-none'>
            <span className='absolute -inset-1.5' />
            <span className='sr-only'>Open translation menu</span>
            <MdOutlineTranslate className='h-7 w-7' aria-hidden='true' />
          </MenuButton>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems className='absolute left-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden'>
            {lang !== 'fi' && (
              <MenuItem>
                {({ focus }) => (
                  <Link
                    onClick={() => setLang('fi')}
                    to='#'
                    className={classNames(
                      focus ? 'bg-gray-100' : '',
                      'block p-2 text-sm text-gray-700 border-b-gray-200'
                    )}
                  >
                    FI
                  </Link>
                )}
              </MenuItem>
            )}
            {lang !== 'en' && (
              <MenuItem>
                {({ focus }) => (
                  <Link
                    onClick={() => setLang('en')}
                    to='#'
                    className={classNames(
                      focus ? 'bg-gray-100' : '',
                      'block p-2 text-sm text-gray-700 border-b-gray-200'
                    )}
                  >
                    EN
                  </Link>
                )}
              </MenuItem>
            )}
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
}

const icons = [
  {
    name: ['restaurant', 'ravintola'],
    value: MdRestaurant,
  },
  {
    name: ['office', 'toimisto'],
    value: FaBuildingColumns,
  },
  {
    name: ['supermarket', 'myymälä'],
    value: FaStoreSlash,
  },
  {
    name: ['house', 'koti'],
    value: FaHouse,
  },
  {
    name: ['other', 'muu'],
    value: FaCreativeCommonsRemix,
  },
];

export default function Navigation({ className }: { className: string }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { lang, translate } = useTranslation();

  const { services } = useServiceTranslation();

  function getServiceNavigation(services: ServiceNavigation[]) {
    interface ServiceTranslationSubNav {
      name: string;
      href: string;
      icon: IconType;
    }

    const result: ServiceTranslationSubNav[] = [];

    if (services && services.length > 0) {
      services.forEach((service) => {
        const icon = icons.find((icon) =>
          icon.name.includes(service.translations.serviceCategory)
        )?.value;

        if (icon) {
          result.push({
            name: lang === 'en' ? `${capitalize(service.translations.serviceCategory)} cleaning` : `${capitalize(service.translations.serviceCategory)} siivous`,
            href: `/services/${service.id}`,
            icon: icon,
          });
        }
      });
    }

    return result;
  }

  const serviceTrans = getServiceNavigation(services);

  const navigation = [
    {
      name: capitalize(translate(translations[lang].nav.home)),
      href: '/',
      subNav: [],
    },
    {
      name: capitalize(translate(translations[lang].nav.services)),
      href: '#',
      subNav: serviceTrans,
    },
    {
      name: capitalize(translate(translations[lang].nav.enquiries)),
      href: '/enquiries',
      subNav: [],
    },
    {
      name: capitalize(translate(translations[lang].nav.recruitment)),
      href: '/recruitments',
      subNav: [],
    },
    {
      name: capitalize(translate(translations[lang].nav.contacts)),
      href: '/contacts',
      subNav: [],
    },
    {
      name: capitalize(translate(translations[lang].nav.aboutUs)),
      href: '/about-us',
      subNav: [],
    },
    {
      name: capitalize(translate(translations[lang].nav.references)),
      href: '/testimonials',
      subNav: [],
    },
  ];

  return (
    <header className={`${className && className} bg-white`}>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <Link to='/' className='h-16 mr-10'>
            <span className='sr-only'>MAW Company</span>
            <img
              className='h-16 w-auto'
              src={logoWithText}
              alt='Logo'
            />
          </Link>
        </div>
          <div className='flex lg:hidden'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className='sr-only'>
                {mobileMenuOpen ? 'Close menu' : 'Open main menu'}
              </span>
              {mobileMenuOpen ? (
                <XMarkIcon className='h-6 w-6' aria-hidden='true' />
              ) : (
                <Bars3Icon className='h-6 w-6' aria-hidden='true' />
              )}
            </button>
        </div>
        {/* Sub-menu popover */}
        <PopoverGroup className='hidden lg:flex lg:gap-x-12'>
          {navigation.map((nav) =>
            nav.subNav.length === 0 ? (
              <Link
                to={nav.href}
                key={nav.name}
                className='text-sm font-semibold leading-6 text-gray-900'
              >
                {nav.name}
              </Link>
            ) : (
              <Popover key={nav.name} className='relative'>
                <div>
                  <PopoverButton className='flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900'>
                    {nav.name}
                    <ChevronDownIcon
                      className='h-5 w-5 flex-none text-gray-400'
                      aria-hidden='true'
                    />
                  </PopoverButton>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='opacity-0 translate-y-1'
                  enterTo='opacity-100 translate-y-0'
                  leave='transition ease-in duration-150'
                  leaveFrom='opacity-100 translate-y-0'
                  leaveTo='opacity-0 translate-y-1'
                >
                  <PopoverPanel className='absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5'>
                    <div className='p-4'>
                      {nav.subNav.map((item) => (
                        <div
                          key={item.name}
                          className='group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'
                        >
                          <div className='flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white'>
                            <item.icon
                              className='h-6 w-6 text-gray-600 group-hover:text-green-600'
                              aria-hidden='true'
                            />
                          </div>
                          <div className='flex-auto'>
                            <Link
                              to={item.href}
                              className='block font-semibold text-gray-900'
                            >
                              {item.name}
                              <span className='absolute inset-0' />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </PopoverPanel>
                </Transition>
              </Popover>
            )
          )}
        </PopoverGroup>
        {/* End of sub-menu popover */}
        <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
          <DropdownMenu />
        </div>
      </nav>
      {/* Mobile  */}
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <DialogPanel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <Link to='/' className='-m-1.5 p-1.5'>
              <span className='sr-only'>MAW Company</span>
              <img
                className='h-10 w-auto'
                src={logoNoText}
                alt='Logo'
              />
            </Link>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-16 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigation.map(
                  (nav) =>
                    nav.subNav.length === 0 && (
                      <Link
                        onClick={() => setMobileMenuOpen(false)}
                        to={nav.href}
                        key={nav.name}
                        className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                      >
                        {nav.name}
                      </Link>
                    )
                )}
                <Disclosure as='div' className='-mx-3'>
                  {({ open }) => (
                    <>
                      {navigation.map(
                        (nav) =>
                          nav.subNav.length > 0 && (
                            <div key={nav.name}>
                              <DisclosureButton className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                {nav.name}
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? 'rotate-180' : '',
                                    'h-5 w-5 flex-none'
                                  )}
                                  aria-hidden='true'
                                />
                              </DisclosureButton>
                              <DisclosurePanel className='mt-2 space-y-2'>
                                {nav.subNav.map((item) => (
                                  <DisclosureButton
                                    key={item.name}
                                    as='a'
                                    href={item.href}
                                    className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                                  >
                                    {item.name}
                                  </DisclosureButton>
                                ))}
                              </DisclosurePanel>
                            </div>
                          )
                      )}
                    </>
                  )}
                </Disclosure>
              </div>
              <div className='py-6'>
                <DropdownMenu />
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
