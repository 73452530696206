import { capitalize } from '@maw/utility';
import { translations } from '../../data/translation';
import { useTranslation } from '../../store/translation';
import Container from '../Container';
import TestimonialSection from '../TestimonialSection';
import {
  getLocaleFromStorage,
  getResources,
  queryClient,
} from '../../util/requests';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import BounceModal from '../modals/BounceModal';
import { LoaderFunction } from 'react-router-dom';

export default function Testimonials() {
  const { translate, lang } = useTranslation();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['testimonials', lang],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'testimonials', locale: lang }),
  });

  const testimonials: Testimonial[] = data?.data;

  useEffect(() => {
    refetch();
  }, [lang, refetch]);

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          <section className='mx-auto max-w-7xl lg:px-8'>
            <div className='mx-auto max-w-2xl sm:text-center'>
              <h2 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                {testimonials && testimonials.length
                  ? capitalize(translate(translations[lang].referenceTitle))
                  : capitalize(translate(translations[lang].noData))}
              </h2>
            </div>
            <ul className='my-8'>
              {testimonials &&
                testimonials.length > 0 &&
                testimonials.map((testimonial, index) => (
                  <TestimonialSection
                    className={`${
                      testimonials.length > 1
                        ? 'last-of-type:border-transparent'
                        : ''
                    } ${index === 1 ? 'flex-col-reverse' : ''}`}
                    id={testimonial.id}
                    key={testimonial.id}
                    leftAlignImage={index % 2 === 0}
                    photo={testimonial.photo}
                    postStatus={testimonial.postStatus}
                    firstName={testimonial.firstName}
                    lastName={testimonial.lastName}
                    companyName={testimonial.companyName}
                    description={testimonial.description}
                  />
                ))}
            </ul>
          </section>
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  return await queryClient.fetchQuery({
    queryKey: ['testimonials'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'testimonials', locale }),
  });
}
