import { useQuery } from '@tanstack/react-query';
import { CustomDate } from '@maw/utility';
import { capitalize } from '@maw/utility';
import Container from '../Container';
import { Link } from 'react-router-dom';
import { getResources, queryClient } from '../../util/requests';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';
import { useEffect } from 'react';
import BounceModal from '../modals/BounceModal';
import { useMeta } from '../../hooks/metadata';

export default function Recruitments() {
  const { translate, lang } = useTranslation();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['recruitments'],
    queryFn: ({ signal }) => getResources({ signal, url: 'recruitments' }),
  });

  const recruitments: Recruitment[] = data?.data;

  useEffect(() => {
    refetch();
  }, [refetch]);

  useMeta({
    title: `${translate(translations[lang].positions.open)}`,
  });

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          <div className='h-screen -m-6 relative'>
            <div
              className='h-full relative bg-gray bg-cover bg-center bg-no-repeat bg-blend-overlay bg-fixed bg-[url("/src/assets/ai-image-3.jpg")]'
            >
              &nbsp;
            </div>
            {/* Jobs */}
            <section className='w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-700 bg-opacity-40 rounded-md  max-w-5xl lg:px-8 p-4'>
              <>
                <h2 className='text-2xl font-bold tracking-wide text-white sm:text-4xl my-8'>
                  {recruitments.length
                    ? `${translate(translations[lang].positions.open)}`
                    : `${translate(translations[lang].positions.no)}`}
                </h2>
                {recruitments.length > 0 && (
                  <div className='hidden md:flex md:justify-between mb-4 text-white'>
                    <h1 className='w-2/3'>
                      {capitalize(translate(translations[lang].jobTitle))}
                    </h1>
                    <h1 className='w-1/3'>
                      {capitalize(translate(translations[lang].location))}
                    </h1>
                    <h1 className='w-1/3'>
                      {capitalize(translate(translations[lang].deadline))}
                    </h1>
                  </div>
                )}
              </>
              <ul>
                {recruitments.length > 0 &&
                  recruitments.map((recruitment) => (
                    <Link
                      to={`/recruitments/${recruitment.id}`}
                      key={recruitment.id}
                    >
                      <li className='flex md:justify-between gap-4 items-center bg-white shadow-md p-4 rounded-md mb-4 hover:bg-slate-200'>
                        <h1 className='w-2/3 text-2xl text-black'>
                          {recruitment.title}
                        </h1>
                        <p className='w-1/3 text-gray-500'>
                          {recruitment.location}
                        </p>
                        <p className='w-1/3 text-gray-500'>
                          {CustomDate.getDayMonthYearHyphenated(
                            recruitment.expiresAt
                          )}
                        </p>
                      </li>
                    </Link>
                  ))}
              </ul>
            </section>
          </div>
        </Container>
      )}
    </>
  );
}

export async function loader() {
  return await queryClient.fetchQuery({
    queryKey: ['recruitments'],
    queryFn: ({ signal }) => getResources({ signal, url: 'recruitments' }),
  });
}
