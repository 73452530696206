export var limitWord = function (sentence, limit) {
    if (sentence.length <= 1 || limit <= 1) {
        throw new Error('The passed sentence and the limiter must be greater than one.');
    }
    var splitSentence = sentence.split(/\s+/);
    // If limit is more than the number of words, adjust limit
    var actualLimit = Math.min(limit, splitSentence.length);
    var result = splitSentence.slice(0, actualLimit);
    return result.join(' ');
};
