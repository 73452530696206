import { useEffect } from 'react';

interface MetaProps {
  title: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogType?: string;
}

export const useMeta = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogType,
}: MetaProps) => {
  useEffect(() => {
    document.title = title;

    if (description) {
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) metaDescription.setAttribute('content', description);
    }

    if (keywords) {
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) metaKeywords.setAttribute('content', keywords);
    }

    if (ogTitle) {
      const ogTitleTag = document.querySelector('meta[property="og:title"]');
      if (ogTitleTag) ogTitleTag.setAttribute('content', ogTitle);
    }

    if (ogDescription) {
      const ogDescriptionTag = document.querySelector(
        'meta[property="og:description"]'
      );
      if (ogDescriptionTag)
        ogDescriptionTag.setAttribute('content', ogDescription);
    }

    if (ogType) {
      const ogTypeTag = document.querySelector('meta[property="og:type"]');
      if (ogTypeTag) ogTypeTag.setAttribute('content', ogType);
    }
  }, [title, description, keywords, ogTitle, ogDescription, ogType]);
};
