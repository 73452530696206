import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { queryClient } from './util/requests';
import { TranslationProvider } from './store/translation';
import { ServiceTranslationProvider } from './store/service-translation';
import { CookieProvider } from './store/cookie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookieProvider>
      <TranslationProvider>
        <QueryClientProvider client={queryClient}>
          <ServiceTranslationProvider>
            <App />
          </ServiceTranslationProvider>
        </QueryClientProvider>
      </TranslationProvider>
    </CookieProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
