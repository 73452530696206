import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  getLocaleFromStorage,
  getPaginatedData,
  queryClient,
} from '../../util/requests';
import Container from '../Container';
import BlogCard from '../cards/BlogCard';
import Pagination from '../Pagination';
import { useTranslation } from '../../store/translation';
import BounceModal from '../modals/BounceModal';
import { LoaderFunction } from 'react-router-dom';
import { useMeta } from '../../hooks/metadata';
import { capitalize } from '@maw/utility';
import { translations } from '../../data/translation';

export default function BlogPosts() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { lang, translate } = useTranslation();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['blog-posts', currentPage, lang],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage,
        perPage: 12,
        resource: 'blog-posts',
        locale: lang,
      }),
  });

  const paginatedPosts = data?.data;

  const handlePageChange = (
    direction: 'prev' | 'next' | 'specific',
    pageNumber?: number
  ) => {
    if (direction === 'specific' && pageNumber) {
      setCurrentPage(pageNumber);
    } else if (direction === 'prev') {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next') {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, lang]);

  useMeta({
    title: `${capitalize(translate(translations[lang].blog.title))}`,
  });

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          {paginatedPosts.data && paginatedPosts.data.length > 0 && (
            <section className='mt-12 mx-auto max-w-7xl lg:px-8 md:mt-20'>
              <div className='flex gap-4 flex-wrap justify-center mb-8'>
                {paginatedPosts.data.map((post: BlogPost) => (
                  <BlogCard
                    id={post.id}
                    translation={post.translation}
                    key={post.id}
                    author={post.author}
                    coverImage={post.coverImage}
                  />
                ))}
              </div>
              {paginatedPosts &&
                paginatedPosts.total > paginatedPosts.perPage && (
                  <Pagination
                    onHandlePageChange={handlePageChange}
                    total={paginatedPosts.total}
                    perPage={paginatedPosts.perPage}
                    currentPage={paginatedPosts.currentPage}
                  />
                )}
            </section>
          )}
        </Container>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  const page = 1;
  return await queryClient.fetchQuery({
    queryKey: ['blog-posts'],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage: page,
        perPage: 12,
        resource: 'blog-posts',
        locale,
      }),
  });
}
