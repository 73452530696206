import Navigation from './Navigation';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { useCookie } from '../store/cookie';
import CookieModal from './modals/CookieModal';

export default function Layout() {
  const { acknowledged } = useCookie();
  return (
    <div className='relative'>
      <Navigation className='fixed top-0 left-0 right-0 z-50' />
      <Outlet />
      <Footer />
      {acknowledged === 'default' && (
        <CookieModal show={acknowledged === 'default'} />
      )}
    </div>
  );
}
