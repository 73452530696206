import { useQuery } from '@tanstack/react-query';
import {
  getById,
  getLocaleFromStorage,
  getResources,
  queryClient,
} from '../../util/requests';
import { useEffect } from 'react';
import { useTranslation } from '../../store/translation';
import Container from '../Container';
import {
  LoaderFunction,
  LoaderFunctionArgs,
  useParams,
} from 'react-router-dom';
import { ENV } from '../../util/constants';
import BounceModal from '../modals/BounceModal';
import { useMeta } from '../../hooks/metadata';
import TinyMCEContent from '../TinyMCEContent';

export default function ServiceDetails() {
  const { lang } = useTranslation();
  const params = useParams();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['services', params.id, lang],
    queryFn: ({ signal }) =>
      getById({ signal, url: `services/${params.id}`, locale: lang }),
  });

  const service: Service = data?.data;

  useEffect(() => {
    refetch();
  }, [lang, refetch]);

  useMeta({
    title: `${service?.translation?.title}`,
    description: `${service?.translation?.description}`,
  });

  return (
    <>
      {isLoading ? (
        <BounceModal show={isLoading} />
      ) : (
        <Container>
          <section className='max-w-5xl m-auto'>
            <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center my-12'>
              {service.translation.title}
            </h2>
            {service.coverImage && (
              <div className='rounded-lg overflow-clip shadow-md max-h-[40rem]'>
                <img
                  className='w-full object-cover h-full'
                  src={`${ENV.assetsUrl}/${service.coverImage}`}
                  alt={service.translation.title}
                />
              </div>
            )}
              <TinyMCEContent className='my-20' content={service.translation.description} />
          </section>
        </Container>
      )}
    </>
  );
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  return await queryClient.fetchQuery({
    queryKey: ['services', params.id],
    queryFn: ({ signal }) =>
      getResources({
        signal,
        url: `services/${params.id}`,
        locale,
      }),
  });
}
