import { capitalize, limitWord } from '@maw/utility';
import TinyMCEContent from '../TinyMCEContent';

export default function ServiceCard({ translation, icon }: ServiceWithIcon) {
  return (
    <div className='rounded-lg mb-4 p-6 md:mb-0 sm:mb-0 md:w-full lg:w-80 ring-1 ring-green-600'>
      <h1 className='font-bold'>{capitalize(translation.serviceCategory)}</h1>
      <div className='my-3'>{icon.value}</div>
      <TinyMCEContent content={limitWord(translation.description, 40)} />
    </div>
  );
}
