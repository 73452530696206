import { UserGroupIcon, UsersIcon } from '@heroicons/react/24/outline';
import Container from '../Container';
import {
  getLocaleFromStorage,
  getResources,
  queryClient,
} from '../../util/requests';
import { LoaderFunction } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '../../store/translation';
import { useEffect } from 'react';
import { ENV } from '../../util/constants';
import BounceModal from '../modals/BounceModal';
import { translations } from '../../data/translation';
import { capitalize } from '@maw/utility';
import { useMeta } from '../../hooks/metadata';

export default function AboutUs() {
  const { lang, translate } = useTranslation();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['companies', lang],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'companies/about', locale: lang }),
  });

  const aboutUs: AboutUs = data?.data;

  useEffect(() => {
    refetch();
  }, [refetch, lang]);

  useMeta({
    title: `${aboutUs?.companyAbout?.translation?.title || 'Meista'}`,
    description: `${aboutUs?.companyAbout?.translation?.description}`,
  });

  return (
    <>
      {isLoading && <BounceModal show={isLoading} />}
      {!isLoading && aboutUs ? (
        <Container>
          {aboutUs && aboutUs.companyAbout && aboutUs.companyCulture && (
            <section className='mt-12 mx-auto max-w-5xl lg:px-8 md:mt-20'>
              {aboutUs.companyAbout.coverImage && (
                <div className='rounded-lg overflow-hidden shadow-md align-middle'>
                  <img
                    src={`${ENV.assetsUrl}/${aboutUs.companyAbout.coverImage}`}
                    alt='Person'
                    className='object-cover rounded-lg shadow-md w-full'
                  />
                </div>
              )}
              {aboutUs.companyAbout?.translation && (
                <div className='mt-8'>
                  <div className=''>
                    {aboutUs.companyAbout.translation.title && (
                      <h2 className='text-2xl font-bold tracking-wide text-gray-900 sm:text-4xl'>
                        {aboutUs.companyAbout.translation.title}
                      </h2>
                    )}
                    {aboutUs.companyAbout.translation.description && (
                      <div className='mt-6 md:text-lg'>
                        {aboutUs.companyAbout.translation.description}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </section>
          )}
          {/* Stastics section */}
          {(aboutUs.customerCount > 0 || aboutUs.employeeCount > 0) && (
            <section className='-mx-6 mt-20 border-y-green-600 border-y-2'>
              <ul className='flex justify-center flex-col md:flex-row gap-4'>
                {aboutUs.employeeCount > 0 && (
                  <li className='text-green-600 p-6 md:p-10 md:border-r-green-600 border-r-2'>
                    <div className='flex md:justify-center items-center'>
                      <UserGroupIcon className='h-10 w-10' aria-hidden='true' />
                      <h1 className='text-4xl ml-4'>
                        {capitalize(translate(translations[lang].employees))}
                      </h1>
                    </div>
                    <h2 className='text-7xl font-extrabold md:mt-4 mt-0'>
                      {aboutUs.employeeCount}
                    </h2>
                  </li>
                )}
                {aboutUs.customerCount > 0 && (
                  <li className='text-green-600 p-6 md:p-10'>
                    <div className='flex md:justify-center items-center'>
                      <UsersIcon className='h-10 w-10' aria-hidden='true' />
                      <h1 className='text-4xl ml-4'>
                        {capitalize(translate(translations[lang].client))}
                      </h1>
                    </div>
                    <h2 className='text-7xl font-extrabold md:mt-4'>
                      {aboutUs.customerCount}
                    </h2>
                  </li>
                )}
              </ul>
            </section>
          )}
          {/* Our culture section */}
          {aboutUs.companyCulture && (
            <section className='mt-12 mx-auto max-w-5xl lg:px-8 md:mt-20'>
              <div className='flex flex-col justify-center align-middle gap-4 md:space-x-2 lg:flex-row'>
                {aboutUs?.companyCulture.coverImage && (
                  <div className='flex-1 rounded-lg overflow-hidden shadow-md align-middle'>
                    <img
                      src={`${ENV.assetsUrl}/${aboutUs.companyCulture.coverImage}`}
                      alt='Person'
                      className='object-cover rounded-lg shadow-md md:h-full w-full'
                    />
                  </div>
                )}
                {aboutUs.companyCulture?.translation && (
                  <div className='flex-1 align-middle m-auto'>
                    <h2 className='text-2xl font-bold tracking-wide text-gray-900 sm:text-4xl'>
                      {aboutUs.companyCulture.translation.title}
                    </h2>
                    <div className='mt-6 md:text-lg'>
                      <p className='mb-4'>
                        {aboutUs.companyCulture.translation.description}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}
        </Container>
      ) : (
        <h2 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
          {capitalize(translate(translations[lang].noData))}
        </h2>
      )}
    </>
  );
}

export async function loader(): Promise<LoaderFunction> {
  const locale = getLocaleFromStorage();
  return await queryClient.fetchQuery({
    queryKey: ['companies'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'companies/about', locale }),
  });
}
