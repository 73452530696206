import { QueryClient } from '@tanstack/react-query';
import { ENV } from './constants';

export const queryClient = new QueryClient();

export function getLocaleFromStorage() {
  const savedLang = localStorage.getItem('lang');
  if (savedLang === 'en' || savedLang === 'fi') {
    return savedLang;
  }
  return 'fi';
}

interface Options {
  headers?: Record<string, string>;
  body?: any;
  signal?: AbortSignal;
}

export class Http {
  private static defaultHeaders = {
    'X-Client-Token': `${ENV.clientToken}`,
    Accept: 'application/json',
  };

  private static async request(
    url: string,
    method: string,
    options: Options
  ): Promise<any> {
    const headers = {
      ...this.defaultHeaders,
      ...options.headers,
    };

    try {
      const response = await fetch(url, {
        method,
        headers,
        body: options.body,
        signal: options.signal,
      });
      const data = await response.json();
      if (!response.ok) {
        return data;
      }
      return data;
    } catch (error: any) {
      return { error: { message: error.statusText }, statusCode: 500 };
    }
  }

  static async post(url: string, options: Options) {
    return await this.request(url, 'POST', options);
  }

  static async get(url: string, options: Options) {
    return await this.request(url, 'GET', options);
  }

  static async delete(url: string, options: Options) {
    return await this.request(url, 'DELETE', options);
  }

  static async patch(url: string, options: Options) {
    return await this.request(url, 'PATCH', options);
  }

  static async put(url: string, options: Options) {
    return await this.request(url, 'PUT', options);
  }
}

export async function getResources({
  signal,
  url,
  locale = 'fi',
}: {
  signal: AbortSignal;
  url: string;
  locale?: Locale;
}): Promise<any> {
  const fullUrl = `${ENV.baseUrl}/${url}`;
  return await Http.get(`${fullUrl}`, {
    headers: {
      'Accept-Language': locale,
    },
    signal,
  });
}

export async function getById({
  signal,
  url,
  locale = 'fi',
}: {
  signal: AbortSignal;
  url: string;
  locale?: Locale;
}): Promise<any> {
  const fullUrl = `${ENV.baseUrl}/${url}`;
  return await Http.get(fullUrl, {
    headers: {
      'Accept-Language': locale,
    },
    signal,
  });
}

export async function getPaginatedData({
  signal,
  currentPage,
  perPage,
  resource,
  locale,
}: {
  signal: AbortSignal;
  currentPage: number;
  perPage: number;
  resource: string;
  locale: Locale;
}) {
  const url = `${ENV.baseUrl}/${resource}`;
  return await Http.post(`${url}?page=${currentPage}&&per_page=${perPage}`, {
    headers: {
      'Accept-Language': locale,
    },
    signal,
  });
}

export async function upsertResource({
  request,
  resource,
  isFormData,
  locale,
}: {
  request: Request;
  resource: string;
  isFormData: boolean;
  locale: Locale;
}): Promise<any> {
  const url = `${ENV.baseUrl}`;
  const resourceUrl = `${url}/${resource}`;
  const formData = await request.formData();
  const entries = Object.fromEntries(formData);
  const body = isFormData ? formData : JSON.stringify(entries);

  return await Http.post(resourceUrl, {
    headers: {
      'Accept-Language': locale,
    },
    body,
    signal: request.signal,
  });
}
