import { CustomDate } from '@maw/utility';
import placeholderAvatar from '../../assets/generic-avatar.png';
import placeholderImage from '../../assets/placeholder-image.png';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../util/constants';

export default function BlogCard({
  coverImage,
  translation,
  author,
  className,
}: Omit<BlogPost, 'content' | 'postStatus'> & { className?: string }) {
  const navigate = useNavigate();
  return (
    <figure
      onClick={() => navigate(`/blog-posts/${translation.slug}`)}
      className={`${
        className && className
      } relative w-full md:w-96 h-80 rounded-lg hover:shadow-lg overflow-hidden cursor-pointer`}
      style={{
        backgroundImage: `url(${
          !coverImage ? placeholderImage : `${ENV.assetsUrl}/${coverImage}`
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'gray',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
      }}
    >
      <figcaption className='absolute bottom-0 left-0 m-auto text-white p-4 bg-gray-600 bg-opacity-60 w-full'>
        <div className='flex flex-col justify-self-start md:gap-x-2 mb-2 md:flex-row md:items-center'>
          <p className='w-fit'>
            {CustomDate.formatDateWithDay(translation.publishedAt)}
          </p>
          <div className='w-10 h-10 rounded-full'>
            <img
              className='w-full h-full object-cover rounded-full'
              src={`${
                author.avatar
                  ? `${ENV.assetsUrl}/${author.avatar}`
                  : placeholderAvatar
              }`}
              alt={`${author.firstName} ${author.lastName}`}
            />
          </div>
          <p className='w-fit'>{`${author.firstName} ${author.lastName}`}</p>
        </div>
        <h1 className='font-bold w-fit md:text-2xl text-xl'>
          {translation.title}
        </h1>
      </figcaption>
    </figure>
  );
}
