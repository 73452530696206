import { CLink } from '@maw/utility';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';

export default function NotFound() {
  const { translate, lang } = useTranslation();
  return (
    <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-green-600'>404</p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
          {translate(translations[lang].notFound.title)}
        </h1>
        <p className='mt-6 text-base leading-7 text-gray-600'>
          {translate(translations[lang].notFound.description)}
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <CLink href='/' rounded variant='primary'>
            {translate(translations[lang].notFound.backToHome)}
          </CLink>
        </div>
      </div>
    </main>
  );
}
