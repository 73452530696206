export const ENV = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  clientToken: process.env.REACT_APP_CLIENT_TOKEN,
  assetsUrl: process.env.REACT_APP_ASSETS_BASE_URL,
};

export const GENDER: { [key: string]: Gender } = {
  male: 'male',
  female: 'female',
  other: 'other',
};

export const POST_STATUS: { [key: string]: PostStatus } = {
  published: 'published',
  draft: 'draft',
  archived: 'archived',
};

// Email regex
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
